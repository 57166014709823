import React from "react";
import styled from "styled-components";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";

import Footer from "@sections/Footer";
import { Container } from "@components/global";

const Header = styled.h1`
  margin-top: 2rem;
  text-align: center;
`;

const Para = styled.div`
  font-weight: bold;
  text-align: center;
`;

const StandaloneText = styled.div`
  max-width: 991px;
  margin: 0 auto;
  padding: 0 1rem;
  & p {
    margin-bottom: 1rem;
    & strong {
      color: ${(props) => props.theme.color.black.regular};
    }
  }
  li {
    margin-bottom: 0.5rem;
  }
  margin-bottom: 2rem;
`;

const PrivacyPolicyPage = () => (
  <Layout>
    <TopHeader />

    <Container fluid>
      <StandaloneText>
        <Header style={{ marginBottom: "1.5rem" }}>
          Ta strona korzysta z plików cookies
        </Header>

        <p>
          Niniejsza Polityka dotycząca plików cookies (ciasteczek) odnosi się do
          strony internetowej{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>{" "}
          Korzystając ze strony, wyrażasz zgodę na używanie ciasteczek zgodnie z
          niniejsza polityką.
        </p>
        <p>
          Jeśli nie wyrażasz zgody na używanie ciasteczek, masz możliwość
          zdecydować o tym, zmieniając ustawienia w swojej przeglądarce
          internetowej, bądź zrezygnować z korzystania ze strony{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>
        </p>
        <p>Czym są pliki Cookies (Ciasteczka) ?</p>
        <p>
          Ciasteczka ang. cookies są to informacje tekstowe wysyłane przez
          serwer www, a następnie zapisywane i przechowywane na komputerze lub
          urządzeniu mobilnym użytkownika. Zawierają one najczęściej informacje
          o stronie, która je generuje, unikalny numer oraz czas ich
          przechowywania. Domyślnie tylko serwer, który generuje ciasteczko,
          potrafi je odczytać.
        </p>
        <p>Do czego używa się plików Cookies ?</p>
        <p>Ciasteczka są stosowane do:</p>
        <p>
          -optymalizacji korzystania z strony internetowej
          <br />
          -wygody użytkownika serwisu (zapisywania danych często podawanych
          przez użytkownika i automatycznego wypełniania formularzy tymi danymi)
          <br />
          -dostosowania zawartości strony, do upodobań użytkownika
          <br />
          -zbierania anonimowych statystyk w celu wykonania dalszych analiz
        </p>
        <p>
          Ciasteczka używa się najczęściej w momencie korzystania z takich
          mechanizmów stron jak: sondy, ankiety, zakupy przez stronę,
          wyświetlanie reklam, monitorowanie ruchu i aktywności na stronie.
        </p>
        <p>
          Z jakich plików Cookies korzysta
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>{" "}
          ?
        </p>
        <p>
          Na stronie{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>{" "}
          są stosowane dwa rodzaje plików Cookie:
        </p>
        <p>
          * stałe – pliki te pozostają na dysku użytkownika do czasu ich
          wygaśnięcia - umożliwiające korzystanie z niektórych funkcji serwisu,
          gromadzą one dane statystyczne
        </p>
        <p>
          * sesyjne – ich usunięcie następuje po zamknięciu przeglądarki –
          umożliwiają korzystanie ze wszystkich funkcji strony
        </p>
        <p>
          Korzystając ze strony{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>{" "}
          możliwe jest otrzymanie ciasteczek z serwisów zewnętrznych, które
          współpracują ze stroną{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>{" "}
          takich jak Google, MailGun, plugin Smartlook.
        </p>
        <p>Usuwanie plików Cookies</p>
        <p>
          Większość przeglądarek internetowych ma domyślnie ustawione zezwolenie
          na przyjmowanie ciasteczek. Użytkownik może zmienić te ustawienia w
          następujący sposób:
        </p>
        <p>
          -może zablokować ciasteczka w całości lub części
          <br />
          -może również wybrać opcję, aby przeglądarka internetowa informowała
          go o tym, że ciasteczka zapisywane są na urządzeniu Użytkownika
        </p>
        <p>
          Więcej informacji na temat blokowania plików Cookies, możesz znaleźć
          na stronie stronie Twojej przeglądarki internetowej.
        </p>
        <p>
          Blokada ciasteczek strony internetowej{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>{" "}
          może wpłynąć ze skutkiem negatywnym na korzystanie ze strony lub
          całkowicie uniemożliwić korzystanie z jej niektórych funkcji i
          obszarów.
        </p>

        <Header style={{ marginBottom: "1.5rem" }}>Polityka prywatności</Header>
        <p>
          Niniejsza polityka prywatności przedstawia zasady postępowania z
          Państwa danymi osobowymi oraz osób uprawnionych do kierowania pojazdem
          w ramach strony internetowej{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>
        </p>
        <p>
          Administratorem strony oraz Państwa danych osobowych oraz osób
          uprawnionych do kierowania pojazdem jest RENT ME CAR, Rogalskiego 10,
          03-982 Warszawa.
          {/* Administratorem strony oraz Państwa danych osobowych oraz osób
          uprawnionych do kierowania pojazdem jest Adam Manikowski, Dworszowice
          Kościelne Kolonia 31, 98-331 Nowa Brzeźnica, NIP: 5080067829 REGON
          36942536. */}
          <br />Z administratorem kontaktować się można pod adresem e-mail:
          rentmecar24@gmail.com
        </p>
        <p>
          <Para>
            § 1<br />
            Definicje
          </Para>
          <br />W niniejszej polityce prywatności, przyjmuje się następujące
          znaczenie poniższych definicji:
          <ul>
            Administrator – RENT ME CAR, Rogalskiego 10, 03-982 Warszawa.
            {/* <li>
              Administrator – Adam Manikowski, Dworszowice Kościelne Kolonia 31,
              98-331 Nowa Brzeźnica, NIP: 508-006-78-29 REGON 36942536
            </li> */}
            <li>
              Strona – serwis internetowy dostępny pod adresem{" "}
              <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
                https://www.najtansza-wypozyczalnia-samochodow.pl
              </a>
            </li>
            <li>
              Najemca – każdy podmiot, który korzysta ze Strony oraz podaje
              swoje dane osobowe za pomocą Formularzy dostępnych na stronie lub
              zawiera/wyraża chęć zawarcia umowy najmu pojazdu.
            </li>
          </ul>
        </p>
        <p>
          <Para>
            § 2<br />
            Dane osobowe
          </Para>
          <ol>
            <li>
              Najemca może dobrowolnie podać własne dane osobowe
              Administratorowi za pomocą formularzy dostępnych na Stronie,
              osobiście podczas zawierania Umowy, od pracodawcy, współpracownika
              lub innej osoby z którą współpracuje Najemca, od firm trzecich z
              którymi Najemca zawarł umowę, a przekazane dane są konieczne do
              przygotowania lub wykonania świadczonej usługi. Przykładami
              formularzy są: formularz rezerwacji samochodu, formularz
              kontaktowy, czy newsletter.
            </li>
            <li>
              Dane zbierane przez Administratora:
              <br />
              -imię i nazwisko
              <br />
              -numer telefonu
              <br />
              -adres e-mail
              <br />
              -PESEL
              <br />
              -adres zameldowania
              <br />
              -numer dokumentu tożsamości
              <br />
              -numer prawa jazdy
              <br />W przypadku płatności kartą Administrator pobierze od
              Najemcy dodatkowe dane karty:
              <br />
              -typ karty
              <br />
              -numer karty
              <br />
              -daty ważności karty
            </li>
            <li>
              Administratorem danych osobowych Najemcy jest Administrator.
            </li>
            <li>
              Dane przekazane Administratorowi w ramach formularza zapisu na
              newsletter przetwarzane są w celu przesyłania Najemcy newsletteru.
            </li>
            <li>
              Dane przekazane Administratorowi przetwarzane są w celu:
              <br />
              -podjęcia przez Najemcę akcji do której służy dany formularz
              <br />
              -przygotowania oraz wykonania umowy najmu pojazdu,
              <br />
              -realizacji przygotowanej umowy,
              <br />
              -współpracy z partnerami Administratora,
              <br />
              -umożliwienia obsługi kart płatniczych,
              <br />
              -umożliwieniu kontaktu pomiędzy Najemcą, a Administratorem, w
              szczególności ułatwienia kontaktu w sprawie zapytań, uwag oraz
              problemów zgłaszanych przez Najemcę,
              <br />
              -realizacji obowiązku prawnego (przechowywanie zawartych umów,
              faktur itp.),
              <br />
              -zapobieganiu przestępstwom i korzystaniu z usług Administratora w
              sposób niewłaściwy, bądź niezgodny z prawem oraz Ogólnymi
              Warunkami Najmu Pojazdu
              <br />
              -ubiegania roszczeń oraz ochrony przed roszczeniami np. windykacja
              należności, tworzenie wezwań do zapłaty,
              <br />
              -wypełniania postanowień organów państwowych np. Sądów, Policji,
              <br />
              -kontaktu w celu przedstawienie nowych ofert biznesowych w ramach
              prawnie uzasadnionych interesów administratora,
              <br />
              -tworzenia analiz, statystyk związanych z działaniami Admiratora
              pod kątem rozwoju, jakości obsługi klienta oraz celów
              archiwizacyjnych
              <br />
              -promowania w mediach Administratora <br />
              -marketingowych np. newsletter.
              <br />
              -zapisywania danych w plikach cookies oraz zbierania danych ze
              stron www.
            </li>
            <li>
              Administrator poświadcza poufność wszelkich przekazanych mu danych
              osobowych.
            </li>
            <li>
              Najemca podaje dane dobrowolnie, ale dane te są niezbędne do:
              <br />
              -podjęcia przez Najemcę akcji, do której służy dany formularz,
              <br />
              -przygotowania, zawarcia i realizacji Umowy
            </li>
            <li>
              W przypadku braku zgody na podanie danych w celach marketingowych,
              Administrator nie ma możliwości nawiązania kontaktu, by przekazać
              Najemcy ofertę lub wiadomości reklamowe. Występują przypadki, w
              których przepisy prawa narzucają na Administratora obowiązek
              pobrania od Najemcy niezbędnych danych np. Policja, sądy, względy
              rachunkowe lub podatkowe itp.
            </li>
            <li>
              Administrator informuje iż odbiorcami Twoich danych mogą być osoby
              przez niego uprawnione do przetwarzania danych osobowych:
              <br />
              -pracownicy, współpracownicy Administratora,
              <br />
              -partnerzy, dostawcy oraz usługodawcy Administratora, <br />
              -organy uprawnione przepisami prawa do uzyskiwania informacji o
              danych osobowych np. Sądy, Policja, Inspektorat Transportu
              Drogowego itp.
            </li>
            <li>
              Dane przekazywane będą tylko w celu wykonania i realizacji umów
              oraz usług w zakresie niezbędnym do wykonania danej czynności.
            </li>
            <li>
              Dane osobowe są gromadzone z należytą starannością i odpowiednio
              zabezpieczone przed dostępem do nich przez osoby nieupoważnione.
            </li>
            <li>
              Najemcy zgodnie z obowiązującymi przepisami prawa, przysługują
              następujące uprawnienia:
              <ul>
                <li>
                  prawo do żądania dostępu do swoich danych, ich sprostowania,
                  usunięcia lub ograniczenia przetwarzania,
                </li>
                <li>prawo do wniesienia sprzeciwu wobec przetwarzania,</li>
                <li>prawo do przenoszenia danych,</li>
                <li>
                  prawo do cofnięcia zgody na przetwarzanie danych osobowych w
                  określonym celu, jeżeli Najemca uprzednio taką zgodę wyraził,
                </li>
                <li>
                  prawo do wniesienia skargi do organu nadzorczego w związku z
                  przetwarzaniem danych osobowych przez Administratora
                </li>
              </ul>
            </li>
            <li>
              Pobrane przez Administratora dane przetwarzane będą przez okres
              świadczenia usług. wykonywania umów oraz zobowiązań, okres
              przedawnienia roszczeń wynikający z przepisów prawa oraz okres
              przechowywania dokumentów do celów podatkowych i rachunkowych. W
              szczególnych przypadkach powyższe dane przetwarzane będą do dnia
              uwzględnienia sprzeciwu złożonego na podstawie art. 21 RODO przez
              Najemcę.
            </li>
            <li>
              Administrator informuje niniejszym Najemcę że dane osobowe takie
              jak imię oraz adres e-mail przekazywane są do Stanów Zjednoczonych
              poprzez zapisanie ich w bazach zlokalizowanych na serwerach w
              Stanach Zjednoczonych. Wynika to z faktu, że Administrator
              korzysta z usług firm Google, MailChimp oraz MailGun, których
              dostawcami są podmioty amerykańskie. Podmioty te gwarantują
              poufność danych zapisywanych w ich bazach.
            </li>
            <li>
              Administrator informuje Najemcę, że powierza przetwarzanie danych
              osobowych następującym podmiotom:
              <ul>
                <li>
                  Mailgun Technologies, Inc.; 535 Mission St. San Francisco, CA
                  9410 – w celu korzystania z systemu mailingowego MailGun,
                  służącego do przesyłania faktury,
                </li>
                <li>
                  Rocket Science Group // 675 Ponce de Leon Ave NE, Suite 5000,
                  Atlanta, GA 30308 – w celu korzystania z systemu mailingowego
                  MailChimp, służącego do przesyłania newslettera,
                </li>
                <li>
                  Fakturownia sp. z o.o., ul. Juliana Smulikowskiego 6/8, 00-389
                  Warszawa, NIP: 5213704420 – w celu korzystania z systemu
                  Fakturownia, w ramach którego generowane i wysyłane są do
                  Użytkowników faktury dokumentujące zakupy dokonane za
                  pośrednictwem Strony,
                </li>
                <li>
                  Google Ireland Limited – w celu korzystania z usług Google w
                  ramach pakietu G-Suite, w tym poczty e-mail funkcjonującej w
                  oparciu o serwery Google,
                </li>
                <li>
                  podmiotom świadczącym dla Administratora usługi doradcze, w
                  tym informatyczne, księgowe oraz prawne,
                </li>
                <li>bankom oraz instytucjom płatniczym.</li>
              </ul>
            </li>
          </ol>
        </p>
        <p>
          <Para>
            § 3<br />
            Inne technologie
          </Para>
          Administrator informuje niniejszym Użytkownika, że stosuje następujące
          technologie śledzące działania podejmowane przez Użytkownika w ramach
          Strony:
          <ul>
            <li>
              kod śledzenia Google Analitycs – w celu analizy statystyk Strony.
            </li>
            <li>plugin Smartlook - w celu analizy użyteczności Strony.</li>
          </ul>
        </p>
        <p>
          <br />
          <Para>
            § 4<br />
            Logi serwera
          </Para>
          <ul>
            <li>
              Korzystanie ze Strony wiąże się z przesyłaniem zapytań do serwera,
              na którym przechowywana jest Strona.
            </li>
            <li>
              Każde zapytanie skierowane do serwera zapisywane jest w logach
              serwera. Logi obejmują m.in. adres IP Najemcy, datę i czas
              serwera, informacje o przeglądarce internetowej i systemie
              operacyjnym, z jakiego korzysta Najemca.
            </li>
            <li>Logi zapisywane i przechowywane są na serwerze.</li>
            <li>
              Dane zapisane w logach serwera nie są kojarzone z konkretnymi
              osobami korzystającymi ze Strony i nie są wykorzystywane przez
              Administratora w celu identyfikacji Najemcy.
            </li>
            <li>
              Logi serwera stanowią wyłącznie materiał pomocniczy służący do
              administrowania Stroną, a ich zawartość nie jest ujawniana nikomu
              poza osobami upoważnionymi do administrowania serwerem.
            </li>
          </ul>
        </p>
        <p>
          Najemca poprzez zawarcie Umowy Najmu Pojazdu z Administratorem ,
          Najemca oraz osoba upoważniona do kierowania pojazdem wyraża zgodę na
          przetwarzanie swoich danych osobowych w celu świadczenia usług drogą
          elektroniczną, świadczenia usług najmu pojazdów jak i w celach
          marketingowych.
        </p>
        <p>
          W przypadku zmian prawnych bądź zmian dotyczących regulacji wewnątrz
          Administratora dotyczących przetwarzania danych osobowych - będą one
          na bieżąco aktualizowane. Wszelkie informacje będą umieszczane na
          stronie internetowej{" "}
          <a href="https://www.najtansza-wypozyczalnia-samochodow.pl">
            https://www.najtansza-wypozyczalnia-samochodow.pl
          </a>
        </p>
        <p>
          Powyższa informacja realizuje obowiązek prawny wynikający z
          Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
          kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy. Nie wymaga ona żadnych działań
          z Pani/Pana strony i wchodzi w życie z dniem 25 maja 2018r.
        </p>
      </StandaloneText>
    </Container>
    <Footer />
  </Layout>
);

export default PrivacyPolicyPage;
